import { LoginCallback as OktaLoginCallback } from '@okta/okta-react';

export const LoginCallback = (
  <OktaLoginCallback
    errorComponent={({ error }) => {
      localStorage.setItem('error', error.message);
      // This div won't get rendered, but we need to return an html element.
      return <div>Error.</div>;
    }}
  />
);
