import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Landing from './pages/Landing';
import Notifications from './pages/Notifications';
import Project, { routeName as ProjectRoute } from './pages/Project';
import Job from './pages/Job';
import Search, { routeName as SearchRoute } from './pages/Search';
import { LoginCallback } from './components/auth/LoginCallback';

export const AppRoutes = (
  <Routes>
    <Route path={SearchRoute} element={<Search />} />
    <Route path={`/${ProjectRoute}/:id`} element={<Project />} />
    <Route path={`/${ProjectRoute}/:projectId/job/:soNumber`} element={<Job />} />
    <Route path="/home" element={<Home />} />
    <Route path="/notifications/:id?" element={<Notifications />} />
    <Route path="/login" element={LoginCallback} />
    <Route path="/" element={<Landing />} />
    <Route path="*" element={<Navigate replace to="/home" />} />
  </Routes>
);
